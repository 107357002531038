<template>
  <!--服务商订单评价统计列表-->
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <!--搜索条件-->
    <el-card shadow="never" style="margin-top: 20px;">
      <div class="card">
        <div class="card_t">
          <div class="card_t_icon"></div>
          <div class="card_t_text">
            筛选条件
          </div>
        </div>
        <div class="card_b">
          <!-- 搜索表单 -->
          <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
            <el-row :gutter="15">
              <el-col :lg="6" :md="12">
                <el-form-item label="客户名称:">
                  <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="请输入客户名称"/>
                </el-form-item>
              </el-col>
              <!--<el-col :lg="6" :md="12">-->
              <!--  <el-form-item label="服务商名称:">-->
              <!--    <el-input-->
              <!--        clearable-->
              <!--        v-model="where.facilitator_name"-->
              <!--        placeholder="请输入客户名称"/>-->
              <!--  </el-form-item>-->
              <!--</el-col>-->
              <el-col :lg="6" :md="12">
                <el-form-item label="选择年月:">
                  <el-date-picker
                      v-model="where.time"
                      type="month"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :lg="6" :md="12">
                <div class="ele-form-actions">
                  <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>

    <!--数据表格-->
    <el-card shadow="never">
      <div class="card">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              数据列表
            </div>
          </div>
        </div>

        <div class="card_b">
          <!-- 数据表格 -->
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
          </my-table>

        </div>
      </div>
    </el-card>

    <!--列表设置弹窗-->
    <div class="dialog">
      <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  get_order_statistics,
} from '@/api/custom'

export default {
  // 组件生效
  components: {
    PackageSortElement
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "编号",
        //   prop: "id",
        //   isShow: true,
        //   fixed: 'left'
        // },
        {
          label: "统计时间",
          prop: "statistical_time",
          isShow: true
        },
        {
          label: "服务类型",
          prop: "service_type",
          isShow: true
        },
        {
          label: "服务商名称（全称）",
          prop: "provider_name",
          isShow: true
        },
        {
          label: "首派成功率",
          prop: "initial_success_rate",
          isShow: true
        },
        {
          label: "首派成功率得分",
          prop: "initial_success_score",
          isShow: true
        },
        {
          label: "平均服务时长",
          prop: "average_service_time",
          isShow: true
        },
        {
          label: "系统服务时长",
          prop: "system_service_time",
          isShow: true
        },
        {
          label: "平均服务时长得分",
          prop: "average_service_score",
          isShow: true
        },
        {
          label: "客户评价得分",
          prop: "client_evaluation_score",
          isShow: true
        },
        {
          label: "有效投诉率",
          prop: "valid_complaint_rate",
          isShow: true
        },
        {
          label: "有效投诉率扣分",
          prop: "valid_complaint_deduct",
          isShow: true
        },
        {
          label: "改派成功率",
          prop: "branch_success_rate",
          isShow: true
        },
        {
          label: "系统平均改派成功率",
          prop: "system_branch_success_rate",
          isShow: true
        },
        {
          label: "改派成功率得分",
          prop: "branch_success_score",
          isShow: true
        },
        {
          label: "服务商服务能力评价得分",
          prop: "appraise_capacity_evaluation_score",
          isShow: true
        },
        {
          label: "NPS评价得分",
          prop: "npc_evaluation_score",
          isShow: true
        },
        {
          label: "结果是否合格",
          prop: "result_qualified",
          isShow: true
        },
        // {
        //   label: "合作状态",
        //   prop: "authentication_status",
        //   slot: "authentication_status",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      get_order_statistics(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(){
      this.$router.push({
        path:'/custom/details.vue'
      })
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
